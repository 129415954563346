
<template>
  <div class="register-container">
    <a-form class="register-form" :form="registForm" @submit="handleSubmit">
      <div class="form-header">基本信息</div>
      <div class="form-container">
        <a-row>
          <a-col :span="12">
            <a-form-item
              requied
              label="公司名称"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                placeholder="请输入与您营业执照相同的公司名称"
                v-decorator="[
                  'registerAgentUserInfo.company',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="航协号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.iata',
                  {
                    rules: [{ validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="OFFICE号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.office',
                  {
                    rules: [{ validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="所在城市"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
            <a-cascader
              :options="cities"
              placeholder="请选择城市"
              v-decorator="[
                  'registerAgentUserInfo.cities',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                  },
                ]"
              @change="handleCitiesChange"
            />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="银行开户名"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.accountName',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="营业执照"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.license',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="开户银行"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.bank',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="银行账号"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.accountNumber',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="form-header">联系信息</div>
      <div class="form-container">
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="业务联系人"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.businessContact',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="业务联系电话"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.businessMobile',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="财务联系人"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.financeContact',
                  {
                    rules: [{ validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="财务联系电话"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.financeMobile',
                  {
                    rules: [{ validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="航班不正常联系手机"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.fltExpMobile',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="公司地址"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.address',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="联系邮箱"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.email',
                  {
                    rules: [{ validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="所属销售大区"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-select
                style="width: 120px"
                deptLoading
                v-decorator="[
                  'registerAgentUserInfo.deptId',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                  },
                ]"
              >
                <a-select-option
                  :value="dept.id + ''"
                  v-for="(dept, index) in deptOptions"
                  :key="index"
                >
                  {{ dept.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="form-header">资质证明附件</div>
      <div class="form-container">
        <a-row>
          <a-col :span="24">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-upload
                accept=".jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.zip,.rar,.pdf"
                name="file"
                :multiple="true"
                list-type="picture-card"
                action="/api/upload/normal"
                :headers="{
                  token,
                }"
                :fileList="fileList"
                @change="onUpload"
              >
                <div v-if="fileList.length < 8">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
              <span>备 注: 请提交营业执照、营业场所等照片，支持jpeg,jpg,png,pdf,docx,xlsx,zip,rar格式附件。</span>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div class="form-header">登录账号信息</div>
      <div class="form-container">
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="登录用户名"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.username',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="登录密码"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                type="password"
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.password',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="确认登录密码"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-input
                type="password"
                size="small"
                class="register-input-radius"
                v-decorator="[
                  'registerAgentUserInfo.confirmPassword',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    trigger: 'blur',
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              label="滑块验证码"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <admin-slider-captcha
                ref="verify"
                size="large"
                @change="onCaptchVerify"
              >
              </admin-slider-captcha>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row>
          <a-col :span="24">
            <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-checkbox
                v-decorator="[
                  'agree',
                  {
                    rules: [{ required: true, validator: handleFieldValidate }],
                    valuePropName: 'checked'
                  },
                ]"
              >
                我已阅读、理解并同意航空代理人 <a>《隐私条款》</a>和<a
                  >《规则条款》</a
                >
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row> -->
      </div>
      <div class="form-footer">
        <a-button
          :loading="submitLoading"
          class="button-radius"
          type="primary"
          html-type="submit"
          >申请注册</a-button
        >
      </div>
    </a-form>
  </div>
</template>

<script>
import { validate } from '@/utils/validate'
import { userRegister, fetchDeptList } from '@/api/user'

import cities from '@/dicts/cities'

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 8 },
          wrapperCol: { span: 16 }
        }
        : {}
    },
    token () {
      return this.$ls.get('Access-Token')
    }
  },
  data () {
    return {
      cities,
      formLayout: 'horizontal',
      registForm: this.$form.createForm(this, { name: 'register-agent-user' }),
      submitLoading: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      deptOptions: [],
      deptLoading: false,
      captchaToken: '',
      province: '',
      city: ''
    }
  },
  created () {
    this.fetchDept()
  },
  methods: {
    async fetchDept () {
      this.deptLoading = true
      try {
        const response = await fetchDeptList()
        this.deptLoading = false
        this.deptOptions = response
        console.log(response)
      } catch (e) {
        this.deptLoading = false
        this.$message.error(e.message)
      }
    },
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList }) {
      this.fileList = fileList
    },
    handleFieldValidate (rule, value, callback) {
      validate(
        'register-agent-user',
        this.registForm.getFieldsValue(),
        rule.fullField
      ).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.registForm.validateFields((err, values) => {
        if (!err) {
          if (!this.fileList.length) {
            this.$message.error('请上传资质图片')
            return
          }

          this.submitRegister()
        }
      })
    },
    async submitRegister () {
      const formFields = this.registForm.getFieldsValue()

      const attachments = this.fileList.map((item) => {
        return {
          fileName: item.name,
          path: item.filePath
        }
      })

      this.params = {
        ...{
          audit: {
            action: 'START',
            remark: 'register agent'
          },
          bizDataJson: JSON.stringify({
            ...{
              agree: formFields.agree,
              requestId: this.captchaToken,
              attachments
            },
            ...formFields.registerAgentUserInfo,
            ...{
              province: this.province,
              city: this.city
            }
          })
        }
      }

      // console.log(this.params)

      try {
        const result = await userRegister(this.params)

        console.log(result)
        this.$message.success('已提交注册申请，请待审核通过后登录')
        this.$router.replace({ path: '/login' })
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    onUpload (info) {
      let { fileList } = info

      if (fileList.length) {
        fileList = fileList.map((file) => {
          if (file.response) {
            const { status, data } = file.response

            if (status === 'success') {
              file.name = data.fileName
              file.filePath = data.filePath
            } else {
              file.status = 'fail'
            }
          }

          return file
        })

        this.fileList = fileList
      }
    },
    onCaptchVerify (value) {
      this.captchaToken = value
    },
    handleCitiesChange (value) {
      this.province = value[0]
      this.city = value[1]
    }
  }
}
</script>

<style lang="less" scoped>
.register-container {
  width: 960px;
  margin: 0 auto;
}
.form-header {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: bold;
  padding-left: 30px;
  background: #fff;
  margin-top: 20px;
}
.form-container {
  background: #fff;
  padding: 20px 30px;
  margin-top: 20px;
}

.form-footer {
  text-align: center;
  margin-top: 15px;
  padding: 30px 0;
}
</style>
