var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-container"},[_c('a-form',{staticClass:"register-form",attrs:{"form":_vm.registForm},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"form-header"},[_vm._v("基本信息")]),_c('div',{staticClass:"form-container"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"requied":"","label":"公司名称","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.company',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.company',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small","placeholder":"请输入与您营业执照相同的公司名称"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"航协号","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.iata',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.iata',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"OFFICE号","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.office',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.office',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"所在城市","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.cities',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'registerAgentUserInfo.cities',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                },\n              ]"}],attrs:{"options":_vm.cities,"placeholder":"请选择城市"},on:{"change":_vm.handleCitiesChange}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"银行开户名","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.accountName',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.accountName',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"营业执照","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.license',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.license',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"开户银行","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.bank',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.bank',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"银行账号","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.accountNumber',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.accountNumber',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1)],1),_c('div',{staticClass:"form-header"},[_vm._v("联系信息")]),_c('div',{staticClass:"form-container"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"业务联系人","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.businessContact',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.businessContact',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"业务联系电话","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.businessMobile',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.businessMobile',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"财务联系人","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.financeContact',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.financeContact',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"财务联系电话","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.financeMobile',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.financeMobile',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"航班不正常联系手机","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.fltExpMobile',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.fltExpMobile',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"公司地址","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.address',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.address',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"联系邮箱","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.email',
                {
                  rules: [{ validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.email',\n                {\n                  rules: [{ validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"所属销售大区","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.deptId',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                } ]),expression:"[\n                'registerAgentUserInfo.deptId',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                },\n              ]"}],staticStyle:{"width":"120px"},attrs:{"deptLoading":""}},_vm._l((_vm.deptOptions),function(dept,index){return _c('a-select-option',{key:index,attrs:{"value":dept.id + ''}},[_vm._v(" "+_vm._s(dept.name)+" ")])}),1)],1)],1)],1)],1),_c('div',{staticClass:"form-header"},[_vm._v("资质证明附件")]),_c('div',{staticClass:"form-container"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-upload',{attrs:{"accept":".jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.zip,.rar,.pdf","name":"file","multiple":true,"list-type":"picture-card","action":"/api/upload/normal","headers":{
                token: _vm.token,
              },"fileList":_vm.fileList},on:{"change":_vm.onUpload}},[(_vm.fileList.length < 8)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传")])],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('span',[_vm._v("备 注: 请提交营业执照、营业场所等照片，支持jpeg,jpg,png,pdf,docx,xlsx,zip,rar格式附件。")])],1)],1)],1)],1),_c('div',{staticClass:"form-header"},[_vm._v("登录账号信息")]),_c('div',{staticClass:"form-container"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"登录用户名","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.username',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.username',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"登录密码","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.password',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.password',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"type":"password","size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"确认登录密码","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'registerAgentUserInfo.confirmPassword',
                {
                  rules: [{ required: true, validator: _vm.handleFieldValidate }],
                  trigger: 'blur',
                } ]),expression:"[\n                'registerAgentUserInfo.confirmPassword',\n                {\n                  rules: [{ required: true, validator: handleFieldValidate }],\n                  trigger: 'blur',\n                },\n              ]"}],staticClass:"register-input-radius",attrs:{"type":"password","size":"small"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"滑块验证码","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('admin-slider-captcha',{ref:"verify",attrs:{"size":"large"},on:{"change":_vm.onCaptchVerify}})],1)],1)],1)],1),_c('div',{staticClass:"form-footer"},[_c('a-button',{staticClass:"button-radius",attrs:{"loading":_vm.submitLoading,"type":"primary","html-type":"submit"}},[_vm._v("申请注册")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }